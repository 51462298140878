import React from 'react';
import Layout from '../components/layout';
import { StaticImage } from 'gatsby-plugin-image';
import { projectList } from '../data/projects';
import { cardStyles } from '../components/article-card';
import folder from '../images/folder-32.png';
import git from '../images/github.png';
import link from '../images/link-30.png';

function Projects({ location }) {
  return (
    <Layout location={location} title="projects" page="work">
      <section className="main-projects">
        <div>
          <h2>Experience</h2>
          <div className="work">
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h4>Software Developer @Actserv E.A Ltd</h4>
                <p style={{ fontStyle: 'italic' }}>2021-present</p>
              </div>
              <p>
                Developing and implementing user interfaces for essential applications tailored to meet the
                specific needs of the company's clients in the financial sector.
              </p>
            </div>
          </div>
        </div>
        <div>
          <h2>Projects</h2>
          <div className="projects">
            {projectList.map(project => (
              <div
                key={project.source}
                style={{
                  ...cardStyles,
                  height: '180px',
                  padding: '5px',
                  borderRadius: '4px'
                }}
              >
                <div style={{ width: '100%' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <a
                      href={`https://github.com/w-mbugua/${project.source}`}
                      target="_blank"
                    >
                      <img src={git} alt="git logo" />
                    </a>

                    <a href={project.url} target="_blank">
                      <img src={link} alt="external url" />
                    </a>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-around',
                      height: '80%'
                    }}
                  >
                    <div>
                      <a
                        href={`https://github.com/w-mbugua/${project.source}`}
                        target="_blank"
                        style={{ display: 'inline-flex' }}
                      >
                        <p style={{ fontWeight: 'bold' }}>{project.title}</p>
                      </a>
                      <p>{project.description}</p>
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {project.tools.map(tool => (
                        <span key={tool} style={{ margin: '6px' }}>{tool}</span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
}

function ViewButton() {
  return <button className="view-button"> view project </button>;
}

export const Head = () => <title>Projects</title>;

export default Projects;
