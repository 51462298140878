export const projectList = [
  {
    title: 'Book Lendr',
    description: 'A peer-to-peer book lending system',
    url: 'https://booklendr.wmbugua.codes',
    source: 'booklendr',
    tools: ['TypeScript', 'Node.js', 'GraphQL', 'Next.js']
  },
  {
    title: 'Bible Project',
    description: 'A Bible app that focuses on African languages',
    url: 'https://bibleproject.vercel.app/',
    source: 'bible-project',
    tools: ['TypeScript', 'React', 'Redux Toolkit']
  },
  {
    title: 'wacumbugua.dev',
    description: 'My personal website',
    url: 'https://wacumbugua.dev',
    source: 'wacu-mbugua',
    tools: ['Gatbsy.js', 'GraphQL', 'Node.js']
  }
];
